import { Alert, Typography } from '@mui/material'
import { useState } from 'react'

const eolAlertSx = { borderRadius: 0 }

const eolAlertTitleSx = {
    color: 'white !important',
    fontFamily: 'IntelOne Text',
    fontWeight: 500,
}

const eolAlertMessageSx = {
    color: 'white !important',
    fontFamily: 'IntelOne Text',
}

const EOLAlert = () => {
    const [showAlert, setShowAlert] = useState(true)

    const handleAlertClose = () => {
        setShowAlert(false)
    }

    if (!showAlert) {
        return null
    }

    return (
        <Alert
            icon={false}
            variant="filled"
            severity="error"
            onClose={handleAlertClose}
            sx={eolAlertSx}
        >
            <Typography variant="body1" sx={eolAlertTitleSx}>
                EOL End of Life
            </Typography>
            <Typography variant="body2" sx={eolAlertMessageSx}>
                Intel® Optimization Hub will no longer be available after
                30.09.2024 and will not be supported with any additional
                functional, security, or other updates. All versions are
                provided as is. Intel recommends that users of Intel®
                Optimization Hub discontinue use of the webpage, as well as the
                solutions available, as soon as possible.
            </Typography>
        </Alert>
    )
}

export default EOLAlert
